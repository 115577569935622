import { useState, useEffect, useContext } from 'react';
import { Language } from '../context/language'

import { FormProvider, useForm, useFormContext } from "react-hook-form";


const AlertPrefs = () => {
    const { register, watch } = useFormContext();
    const watchPartnerAlertsEnabled = watch("partnerAlertsEnabled", false);

    const {language} = useContext(Language);
  	const _language = language.lang == 'en';

    return (
        <div className='alertpref'>
            <table class="alertsource" style={{marginBottom:'20px'}}>
                <thead>
                    <tr>
                        <th>{_language ? 'Source of Alerts' : 'Fuente de los mensajes de alerta'}</th>
                        <th>Email</th>
                        <th>SMS</th>
                        <th>{_language ? 'Voice' : 'Voz'}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{_language ? 'Alerts about changes to my voter information from VoteFlare' : 'Los mensajes de alerta sobre los cambios a mi información electoral de VoteFlare'} </td>
                        <td>
                            <input type="checkbox"  value="email" {...register('alertPrefsMain')} />
                        </td>
                        <td>
                            <input type="checkbox"  value="sms"  {...register('alertPrefsMain')} />
                        </td>
                        <td>
                            <input type="checkbox" value="voice"  {...register('alertPrefsMain')} />
                        </td>
                    </tr>
                    {
                        watchPartnerAlertsEnabled &&
                        <tr>
                            <td>
                                Alerts about changes to my voter information from VoteFlare Partners
                                <a href="/terms/">*</a>
                            </td>
                            <td>
                                <input type="checkbox" value="email"  {...register('alertPrefsPartner')} />
                            </td>
                            <td>
                                <input type="checkbox" value="sms" {...register('alertPrefsPartner')} />
                            </td>
                            <td>
                                <input type="checkbox" value="voice" {...register('alertPrefsPartner')} />
                            </td>
                        </tr>
                    }
                </tbody>
            </table>
        </div>
    )
};

export default AlertPrefs;
