import React, { useEffect, useState } from "react";
import { FormProvider, useForm, useFormContext } from "react-hook-form";


import AlertPrefs from '../../forms/alertPrefs';

import axios from '../../tools/axiosWrapper';



const SettingsPanel = () =>  {
    const methods = useForm();
    const { register, handleSubmit, watch, setValue, formState: { errors } } = methods;

    const [ isLoading, setIsLoading ] = useState(false);
    const [ isSubmitting, setIsSubmitting ] = useState(false);
    const [ voterInfo, setVoterInfo ] = useState({});

    const onSubmit = (data) => {
        setIsSubmitting(true);

    };

    useEffect(() => {

    }, []);

    if (isLoading) {
        return <p>Loading...</p>
    }

    return (
        <div>
            <h2>Your Settings</h2>
            <p class="is-italic">
                The following information is required to monitor your voter information
            </p>
            <p>First Name: {'Ray'}</p>
            <p>Last Name: {'Boid'}</p>
            <p>County Name: {'Brooks'}</p>
            <p>Date of Birth: {'11-11-1980'}</p>

            <FormProvider {...methods}>
                <form className='mainform' onSubmit={handleSubmit(onSubmit)}>
                    <h3>Alert settings</h3>
                    <AlertPrefs />

                    <button type="submit" disabled={isSubmitting}>
                        {isSubmitting ? "Submitting..." : "Save Changes"}
                    </button>
                </form>
            </FormProvider>
        </div>
    );
};


export default SettingsPanel;
