import { useState, useEffect, useContext, useMemo } from 'react';
import './signup.css'
import { useForm } from "react-hook-form";
import { Navigate, useNavigate } from 'react-router-dom'



const Signup = () => {
  let navigate = useNavigate();


  const { register, handleSubmit, setValue } = useForm({ shouldUseNativeValidation: true });
  const onSubmit = data => {
    navigate('/complete_sign_up')

  };


  useEffect(() => {
    setValue('email', 'rboid@gmail.com')
    setValue('password', 'rboid@gmail.com')

  },[])
  return (
    <>


      <div className='demo' style={{width: '100vw', height: 'calc(100vh - 100px)', display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
      <div className={'demo-signup'}>
        signup
      </div>
        <div style={{width: '400px', height: '400px', backgroundColor:'none'}}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <input {...register("email")} placeholder='email'/>
            <input {...register("password")} type="password" placeholder='password'/>
            <button type="submit">
                {'Sign Up'}
            </button>

          </form>
        </div>

      </div>


    </>
  );
}

export default Signup;
