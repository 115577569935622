import { useState, useEffect, useContext } from 'react';
import { Language } from '../context/language'

import { FormProvider, useForm, useFormContext } from "react-hook-form"
import { Navigate, useNavigate } from 'react-router-dom'
import countyList from "./counties"
import StateSelect from "./stateSelect"
import AlertPrefs from "./alertPrefs"
import axios from '../tools/axiosWrapper'

import {ReactComponent as ProfileSVG} from '../media/icons/profile.svg'
import {ReactComponent as AlertSVG} from '../media/icons/alert.svg'

import './completesignup.css'


const CountySelect = ({ counties }) => {



	const { register } = useFormContext()
	const optionElements = []

	for (const county of counties) {
		let value = county.toLowerCase()
		optionElements.push(<option key={value} value={value}>{county}</option>)
	}

	return (
		<select {...register("county", { required: true })}>
			{optionElements}
		</select>
	)
}


const CompleteSignUp = () => {

	const {language} = useContext(Language);
	const _language = language.lang == 'en';



	const [ isLoading, setIsLoading ] = useState(false)
	const [ isSubmitting, setIsSubmitting ] = useState(false)

	const methods = useForm()

	const { register, handleSubmit, watch, setValue, formState: { errors } } = methods
	const watchState = watch('state', 'GA')

	const [ counties, setCounties ] = useState([])
	const [ isSignedUp, setIsSignedUp ] = useState(false)

	let navigate = useNavigate();

	const onSubmit = (data) => {

		setIsSubmitting(true)
		window.scrollTo(0,0)
		navigate('/portal');


	}

	useEffect(() => {
		let currentState = watchState
		let currentCounties = countyList[currentState]
		setCounties(currentCounties)
	}, [ watchState, setValue ])


		useEffect(() => {

			setValue('email', 'rboid@gmail.com')
			setValue('firstname', 'Ray')
			setValue('lastname', 'Boid')
			setValue('dateOfBirth', '11-11-1980')
			setValue('county', 'albertson')
			setValue('contactEmail', '')
			setValue('contactSms', '123-456-7890')
			setValue('contactVoice', '')
			setValue('alertPrefsMain', '')
			setValue('partnerAlertsEnabled', '')
			setValue('alertPrefsPartner', '')


	}, [])

	if (isSignedUp) {
		return <Navigate to='/portal' />
	}

	if (isLoading) {
		return <p>Loading...</p>
	}

	return (
		<FormProvider {...methods}>
				<form className='mainform' onSubmit={handleSubmit(onSubmit)}>
					<div className='mainform-head'><ProfileSVG/><div>{_language ? 'Your Info' : 'perfil'}</div></div>

					<label>{'Email'}</label>
					<input defaultValue="test" {...register("email")} readOnly />

					<label>{_language ? 'Phone' : 'Teléfono'}</label>
					<input placeholder='123-456-7890' {...register("contactSms")} />

					<label>{_language ? 'First Name' : 'Nombre'}</label>
					<input {...register("firstname", { required: true })}/>
					{errors.firstname && <span>This field is required</span>}

					<label>{_language ? 'Last Name' : 'Apellido'}</label>
					<input {...register("lastname", { required: true })} />
					{errors.lastname && <span>This field is required</span>}

					<label>{_language ? 'Choose a State' : 'Escoja un estado'}</label>
					<StateSelect  />
					{errors.state && <span>This field is required</span>}

					<label style={{marginTop:'20px'}}>{_language ? 'County' : 'Región'}</label>
					<CountySelect counties={counties} />
					{errors.county && <span>This field is required</span>}

					<label style={{marginTop:'20px'}}>{_language ? 'Date of Birth' : 'Fecha de nacimiento'}</label>
					<input {...register("dateOfBirth", { required: true })} placeholder='mm-dd-yyyy'/>
					{errors.dateOfBirth && <span>This field is required</span>}

					<div className='mainform-head' style={{marginTop:'50px'}}><AlertSVG/><div>{_language ? 'Your Alert Settings' : 'Escoja cómo prefiere recibir los mensajes de alerta'}</div></div>
					<AlertPrefs />


					<button type="submit" disabled={isSubmitting}>{isSubmitting ? "Submitting" : "Submit"}</button>
				</form>

		</FormProvider>
	)
}

export default CompleteSignUp
