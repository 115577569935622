import React, { useEffect, useMemo, useState, useContext } from "react";
import { Language } from '../../context/language'



import axios from '../../tools/axiosWrapper';
import {timeParse, timeFormat, utcParse} from "d3-time-format";

function isEmpty(obj) {
    for (var prop in obj) {
        if (obj.hasOwnProperty(prop))
            return false;
    }

    return true;
}

function fieldHasAlert(field, alerts) {
    if (!alerts) {
        return false;
    }

    for (let key of Object.keys(alerts)) {
        if (key.includes(field)) {
            return true;
        }
    }

    return false;
}

function getAlertInField(field, alerts) {
    if (!fieldHasAlert(field)) {
        return null;
    }

    const alert = alerts[`alert_change_${field}_new`] || null;
    return alert;
}

function fieldIsChanged(field, changes) {
    for (let key of Object.keys(changes)) {
        if (key.includes(field)) {
            return true;
        }
    }

    return false;
}

function getChangedField(field, changes) {
    if (!fieldIsChanged(field, changes)) {
        return null;
    }

    const oldValue = changes[`change_${field}_old`] || null;
    const newValue = changes[`change_${field}_new`] || null;

    if (!oldValue && !newValue) {
        return null;
    }

    return {
        oldValue,
        newValue
    };
}



const StatusPanel = ({ title, route }) => {
    const [ isLoadingInfo, setIsLoadingInfo ] = useState(false);
    const [ isLookingUp, setIsLookingUp ] = useState(false);
    const [ lookupFinished, setLookupFinished ] = useState(false);

    const [ info, setInfo ] = useState({});
    const [ changes, setChanges ] = useState({});
    const [ alerts, setAlerts ] = useState({});

    const hasInfo = useMemo(() => !isEmpty(info));
    const hasChanges = useMemo(() => alerts.status === "Warning");


    useEffect(() => {
        fetchData();
    }, []);

    function clearInfo() {
        setInfo({});
        setChanges({});
        setAlerts({});
    }

    function fetchData() {
        setIsLoadingInfo(true);

        axios.get(route)
        .then(res => {
            const { data } = res;

            if (isEmpty(data)) {
                clearInfo();
            } else {
                setInfo(data.info || {});
                setChanges(data.changes);
                setAlerts(data.alerts);
            }
        })
        .catch(error => {
            console.log(error);
        })
        .finally(() => {
            setIsLoadingInfo(false);
        })
    }

    function requestLookup() {
        setIsLookingUp(true);

        return axios.post('/voter/start_lookup')
        .catch(error => {
            console.log(error)
        })
        .finally(() => {
            setIsLookingUp(false);
        });
    }

    return (
        <div>
            <h2>{title} <br/></h2>
            <h3 className='status-detail'>{'This is the voter status currently on file with the state.'}</h3>
            <div style={{border: '1px solid black', padding: '5px'}}>
            {
                isLoadingInfo
                ? <p>Loading...</p>
                : <InfoTable changed={0}/>
            }
            </div>


            <div style={{marginTop:'40px'}}>
            </div>

            {
                lookupFinished &&
                <p>It may take approximately 2 to 10 minutes for your registration info to be updated.</p>
            }
        </div>
    );
};


const StatusLabel = ({hasInfo }) => {

    if (hasInfo) {
        return <div
          style={{ fontSize: '18px', color: "#087A00", fontWeight: '500', margin: '20px 0px'}}>NO MAJOR CHANGES</div>;
    }

    return null;
};

const InfoTable = ({ }) => {

  const [demoChange, setDemoChange] = useState(0)


    const notchanged = (
      <>
      <div
      onClick={() => {
        setDemoChange(1 - demoChange)

      }}

      style={{display:'flex', flexDirection:'column'}}>
        <div style={{display:'flex', alignItems:'center'}}>
          <div style={{margin:'5px', fontSize:'18px', color:'#888', fontWeight:'500', width: '150px'}}>
            NAME:
          </div>
          <div style={{margin:'5px', fontSize:'22px', color:'#000', fontWeight:'500'}}>
            Ray Boid
          </div>
        </div>

        <div style={{display:'flex', alignItems:'center'}}>
          <div style={{margin:'5px', fontSize:'18px', color:'#888', fontWeight:'500', width: '150px'}}>
            DOB:
          </div>
          <div style={{margin:'5px', fontSize:'22px', color:'#000', fontWeight:'500'}}>
            11-11-1980
          </div>
        </div>

        <div style={{display:'flex', alignItems:'center'}}>
          <div style={{margin:'5px', fontSize:'18px', color:'#888', fontWeight:'500', width: '150px'}}>
            STATUS:
          </div>
          <div style={{margin:'5px', fontSize:'22px', color:'#000', fontWeight:'500'}}>
            {!demoChange
              ? <span style={{fontSize:'22px', color:'#000', fontWeight:'500'}}>ACTIVE</span>
              : <span style={{fontSize:'22px', color:'red', fontWeight:'500', textDecoration: 'underline'}}>SUSPENSE</span>
            }
          </div>
        </div>

        <div style={{display:'flex', alignItems:'center'}}>
          <div style={{margin:'5px', fontSize:'18px', color:'#888', fontWeight:'500', width: '200px'}}>
            ADDRESS:
          </div>
          <div style={{margin:'5px', fontSize:'22px', color:'#000', fontWeight:'500'}}>
            398 Reading St, Brooks 78355, Texas
          </div>
        </div>

        <div style={{display:'flex', alignItems:'center'}}>
          <div style={{margin:'5px', fontSize:'18px', color:'#888', fontWeight:'500', width: '150px'}}>
            VALID FROM:
          </div>
          <div style={{margin:'5px', fontSize:'22px', color:'#000', fontWeight:'500'}}>
            01/01/2022
          </div>
        </div>



        <div style={{display:'flex', alignItems:'center'}}>
          <div style={{margin:'5px', fontSize:'18px', color:'#888', fontWeight:'500', width: '150px'}}>
            VUID:
          </div>
          <div style={{margin:'5px', fontSize:'22px', color:'#000', fontWeight:'500'}}>
            1041065768
          </div>
        </div>
        <div style={{display:'flex', alignItems:'center'}}>
          <div style={{margin:'5px', fontSize:'18px', color:'#888', fontWeight:'500', width: '150px'}}>
            PRECINCT:
          </div>
          <div style={{margin:'5px', fontSize:'22px', color:'#000', fontWeight:'500'}}>
            4
          </div>
        </div>
        <div style={{display:'flex', alignItems:'center'}}>
          <div style={{margin:'5px', fontSize:'18px', color:'#888', fontWeight:'500', width: '150px'}}>
            COUNTY:
          </div>
          <div style={{margin:'5px', fontSize:'22px', color:'#000', fontWeight:'500'}}>
            Brooks
          </div>
        </div>

      </div>
      {
        demoChange
        ?
        <div
          style={{ borderTop:'1px solid black', fontSize: '18px', color: "red", fontWeight: '500', padding: '20px 5px'}}>
          {'MAJOR CHANGES FOUND'}
        </div>
        :
        <div
          style={{ borderTop:'1px solid black', fontSize: '18px', color: "#087A00", fontWeight: '500', padding: '20px 5px'}}>
          {'NO MAJOR CHANGES'}
        </div>
      }

      </>

    )





    return (
      <>
        {notchanged}
      </>

    );
};


const FetchButton = ({ isLoadingInfo, fetchData }) => {
    const onClick = () => {
        fetchData();
    };

    return (
        <button className='portal-button' onClick={onClick} disabled={isLoadingInfo}>
            {isLoadingInfo ? 'Loading...' : 'Refresh View'}
        </button>
    );
};

const LookupButton = ({ isLookingUp, setLookupFinished, requestLookup }) => {
    const onClick = () => {
        setLookupFinished(false);
        requestLookup()
        .then(() => {
          setLookupFinished(true);
        });
    };

    return (
        <button className='portal-button' onClick={onClick} disabled={isLookingUp}>
            {isLookingUp ? 'Updating...' : 'Update Information'}
        </button>
    );
}



export default StatusPanel;
