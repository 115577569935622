import React from "react";
import StatusPanel from "./panels/status";
import SettingsPanel from "./panels/settings";
import './portal.css';


const Portal = () => {


    return (
        <div className='portal-main'>
            <div className='portal-status'>
                <StatusPanel title="Your Voter Registration" route="/voter/registration" />
                <br />
                <br />
                <br />

            </div>
            <br />
            <br />
            <br />
            <div>
                <SettingsPanel />
            </div>
        </div>
    );
};


export default Portal;
