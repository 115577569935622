import { useState, useEffect, useContext } from 'react';
import { Language } from '../context/language'

import './other.css'

const Other = ({ page }) => {




  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (

    <>
      <div className= 'other'>
        {
          page.map(i => {
            return(
              <div dangerouslySetInnerHTML={{ __html: i }} />
            )
          })
        }
      </div>
    </>
  );
}

export default Other;
